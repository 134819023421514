import { classNames } from '@pandler/shared/utils';
import { type ReactElement, type ReactNode } from 'react';
import { type BreadcrumbsProps } from '../breadcrumbs';
import { twMerge } from 'tailwind-merge';

export interface PageHeaderProps {
  center?: boolean;
  title?: string;
  left?: ReactNode;
  right?: ReactNode;
  breadcrumbs?: ReactElement<BreadcrumbsProps>;
  className?: string;
}

export function PageHeader({
  title,
  left,
  right,
  breadcrumbs,
  center = true,
  className = '',
}: PageHeaderProps) {
  return (
    <div className={twMerge('bg-white shadow', className)}>
      <div
        className={classNames('px-4 sm:px-6 md:px-8', center ? 'mx-auto' : '')}
      >
        <div className="py-6 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            {left}
            {!left && (
              <>
                {breadcrumbs && <div className="ml-3 mb-1">{breadcrumbs}</div>}
                {title && (
                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                    {title}
                  </h1>
                )}
              </>
            )}
          </div>
          {right && (
            <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">{right}</div>
          )}
        </div>
      </div>
    </div>
  );
}
