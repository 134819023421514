import { classNames } from '@pandler/shared/utils';
import { type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface ContentProps {
  children: ReactNode;
  className?: string;
  containerClassName?: string;
  center?: boolean;
  childrenContainerClassName?: string;
}

export function Content({
  children,
  center = true,
  className = '',
  childrenContainerClassName = '',
  containerClassName = '',
}: ContentProps) {
  return (
    <div className={twMerge('py-6', containerClassName)}>
      <div
        className={twMerge(
          classNames('px-4 sm:px-6 md:px-8', center ? 'mx-auto' : ''),
          className
        )}
      >
        <div className={twMerge('py-4', childrenContainerClassName)}>
          {children}
        </div>
      </div>
    </div>
  );
}
